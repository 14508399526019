import React from "react";
import styles from "../styleDashboard/UpdateSuperVisor.module.css"
export default function UpdateSupervisors() {
  return (
    <div className={styles.AddSuperVisor}>
   
 
    
    </div>
  );
}
