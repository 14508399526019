import React from 'react'
import './PrivacyPolicy.css'
export default function PrivacyPolicy() {

  return (
    <>
        <div className="container" style={{marginBottom:"15%"}}>
        <div className="policy position-relative py-5">
          <h3 className="textm text-danger"> سياسة الخصوصية وشروط الاستخدام  </h3>
        </div>
           <p> شروط استخدام موقع الثورة السورية الإلكتروني و تطبيقاته و فروعه </p>
            <p>الموقع الإلكتروني  https://syrian-revolution1.com، يشار اليه هنا بـ"الموقع"</p>
            <p>يرجى الإطلاع بشكل دقيق على الأحكام والشروط أدناه بتأن: </p>

               <p>  1- واجب المستخدم  </p>
               <p> استخدامك أو زيارتك "للموقع"  فإنك تقر وتوافق على هذه الأحكام والشروط من حين إلى آخر. ودخولك إليه ومشاركتك أو تحميل المواد من "الموقع" يشير إلى موافقتك على الالتزام بتلك الأحكام   </p>
               <p> والشروط، و"للموقع" الحق في تغيير هذه الشروط عبر نشرها عن طريق "الموقع"، ويتعين عليك حينها الإشارة إلى القبول والالتزام بتلك الشروط، وإذا لم توافق على أي من هذه الشروط فيرجى التوقف </p>
               <p>عن استخدام "الموقع" فورا.</p>

            <p> 2- شروط الاستخدام </p>
            <p>يسمح باستخدام هذا "الموقع" لأغراض مشروعة وبطريقة لا تنتهك القانون أو أي من الحقوق أو القيود المفروضة على استخدام هذا "الموقع" من قبل طرف ثالث. </p>
            <p>وتشمل هذه القيود -ولكن لا تقتصر عليها- التحرش  وتشويه السمعة والتشهير باشخاص واستخدام اللغة البذيئة والعدوانية بحق طرف ثالث، وأي تصرف آخر قد يعتبر غير ملائم.</p>
            <p>وبموجب ذلك تتعهد هنا بأن عمرك يزيد عن 16 عاما، و/أو تملك جميع الموافقات والإجراءات القانونية لاستخدام هذا "الموقع"، وتتعهد بأن تلتزم بكافة الشروط والأحكام، كما تتعهد بأنك قادر على الاطلاع </p>
            <p>على تلك الشروط والأحكام والالتزام بها.</p>
            <p>كل المواد الإخبارية المنشورة في الموقع (مقروءة – مرئية – مسموعة) منسوبة لمصادرها سواء كانت منسوخة أو معاد تحريرها أو مترجمة أو أصيلة من نتاج الموقع أو حصرية له، يراعي الموقع فيها </p>
            <p> المصداقية ويتحرى الدقة والصحة، ولا يدعي أنها كذلك دائماً وبالمطلق، ويحتفظ بحق تعديل (تصحيح – نفي – تأكيد – توضيح) أو حذف أي خبر أو معلومة خاطئة أو منقوصة تم نشرها دون دراية أو قصد، </p>
            <p>الطريقة التي يراها مناسبة وفي أي وقت ودون إشعار.</p>
            <p>يسمح الموقع بنسخ وإعادة نشر مواده (مقروءة – مرئية – مسموعة) شرط ذكر المصدر (الموقع) بشكل صريح وتجنب التحوير والاجتزاء والتحرير بطريقة مغلوطة أو مضللة، وعدم ربطها بعناوين كاذبة أو</p>
            <p>مخالفة للمضمون، ويحتفظ الموقع بحق استخدام كل الطرق القانونية المتاحة لتحصيل حقوقه في حال تعرضه لأي انتهاك.</p>
            <p>يحتوي الموقع على روابط لمواقع أخرى، وهو غير مسؤول عن محتوى تلك المواقع الخارجية، وتقع مسؤولية استخدامها على عاتق المستخدم / الزائر.</p>
            <p>لا يطلب موقع الموقع أية بيانات من الزوار، وفي حال قدم أي زائر معلومات عنه سواء بالتعليقات أو عبر خيارات التواصل في تبويب اتصل بنا أو عبر الصفحات التابعة للموقع في مواقع التواصل الاجتماعي </p>
            <p>(اسم – بريد إلكتروني – اهتمامات – أخبار – معلومات …)، فإنه يوافق بأن يقوم الموقع بالاحتفاظ بهذه المعلومات عن طريقه أو عن طريق خدمات شركة تكون طرفاً ثالثاً تقوم بمعالجة هذه المعلومات </p>
            <p>بالنيابة عنه.</p>
            <p>من أجل منح المستخدم / الزائر تجربة أفضل، يستخدم الموقع ملفات تعريف الارتباط (كوكيز – Cookies) .. استخدام خدماتنا يعني موافقة المستخدم / الزائر على استخدامنا لتلك الملفات.</p>
            <p>يجوز للموقع استخدام أية معلومات يقدمها المستخدم / الزائر، من أجل إجراء أبحاث السوق، بما في ذلك التحليل الإحصائي المتعلق بسلوك المستخدم، والتي يجوز له عرضها على أطراف ثالثة على نحو </p>
            <p>غير شخصي، وبشكل كلي، إلى جانب إرسال مخاطبات دورية بخصوص أخبار أو خدمات أو عروض أو غير ذلك.</p>
            <p>يحرص الموقع على حماية خصوصية المعلومات الشخصية التي يتلقاها من مستخدميه / زواره، مع الإشارة إلى أنه يقوم بتسجيل عنوان الـ IP لمن يرسل أية معلومات له (تعليق – بريد إلكتروني – رسالة </p>
            <p>– …)، ويستخدمها للتعرف على احتياجات المستخدمين / الزوار، لا لتجميع أي معلومات أو ملفات شخصية عنهم.</p>
            <p>يحتفظ الموقع بسرية البيانات أو المعلومات (على اختلاف صيغها – مكتوبة مرئية مسموعة) التي يرسلها المستخدم / الزائر إليه، عبر الموقع أو عبر الصفحات التابعة له في مواقع التواصل الاجتماعي، </p>
            <p>إلا إذا تطلب القانون الإفصاح عنها، أو سمح بذلك، كما يحتفظ بها طالما دعت حاجته لذلك.</p>
            
            <p>3- عرض البيانات  </p>
              <p>هناك أجزاء مختلفة من "الموقع" تسمح للمستخدم بعرض بياناته الخاصة وبثها على هذا "الموقع"، يشار إليها هنا بـ "مشاركات المستخدم"، وتشمل بيانات شخصية ومواد سمعية وبصرية وآراء، ولكن </p>
              <p>وفق الشروط التالية: </p>
            <p>3-1- عند عرضك لأي مادة على هذا "الموقع"، فإنك تقبل بتقديم ترخيص عالمي غير محدود وغير قابل للإلغاء، لـ "مشاركات المستخدم"  بالموقع التي يحق "للموقع" بدورها استخدام أو توزيع أو تعديل أو </p>
            <p>  حذف أجزاء من المعروض باعتبار ذلك حقها في حرية التصرف بها في أي منتدى أو وسيلة إعلامية  </p>
            <p>3-2- يمكن "للموقع" استخدام "مشاركات المستخدم" لأي غرض كان دون أن يقتصر على إعادة الإنتاج والبث والتحرير والقص وإعادة الصياغة أو أي استخدام آخر من حق "الموقع". </p>
            <p>3-3- يتحمل المستخدم وحده كافة المسؤولية المترتبة على عرضه لبياناته وعن أي دعاوى وأضرار، وحقوق الطرف الثالث، وأي تصرف أو إجراء قانوني ينجم عن استخدام وعرض المادة على هذا الموقع.</p>
            <p>3-4- بعرضك ل"مشاركات المستخدم" فإنك تتعهد بموجب ذلك وتؤكد بأن لديك كافة الحقوق في عرض المواد وأنك حصلت على جميع التراخيص والحقوق والأذونات اللازمة لاستخدام وتخويل "الموقع" </p>
            <p>باستخدام أي مادة تعرض، بما فيها حقوق براءات الاختراع والعلامات التجارية أو الأسرار التجارية أو حقوق الطبع وحقوق الملكية الأخرى. </p>
            <p>3-5- تتعهد بأنك لن تعرض أو تفشي على هذا الموقع أي معلومات أو بيانات تحظى بحماية حقوق الطبع والنشر أو الأسرار التجارية أو حقوق ملكية خاصة بطرف ثالث بما فيها الخصوصية وحقوق النشر، </p>
            <p>ما لم  يتم الحصول على كافة الحقوق لنشر المواد من مالكيها، ومن ثم تقديم التخويل المشار إليه إلى "الموقع".</p>
            <p>3-6- كما تتعهد بعدم عرض أي مادة أو معلومات تعتبر غير قانونية أو تشهيرية أو بذيئة أو سياسية أو تهديدية، أو تثير الكراهية والعنصرية، أوتعتبر حافزا لارتكاب جريمة ويترتب عليها مسؤولية مدنية،</p>
            <p>وتنتهك القانون أو الأعراض الثقافية أو المعايير المقبولة دوليا والقواعد الأخلاقية. </p>
            <p>3-7- يتعين عليك منح "الموقع" تخويلا دوليا حصريا بلا أجر وغير قابل للإلغاء، من أجل بث وتوزيع وعرض أو إعادة تقديم وتحرير وحذف وبيع المواد أو المعلومات التي تعرضها على الموقع دون قيد، في أي </p>
            <p>وسيلة إعلامية من منطلق حرية التصرف المطلقة لل "الموقع". وهذا التخويل لا يقتصر على مدة معينة أو منطقة. </p>
            <p>3-8- تتنازل "للموقع" (تنازلا دائما عن حقوق الطبع والنشر في الحاضر والمستقبل) عن جميع الحقوق لأي مادة في كل أنحاء العالم مهما كانت طبيعتها، بما فيها التجديدات وحقوق التملك التي تطرأ </p>
            <p>عليها</p>
            <p>3-9- "للموقع" الحق والقول الفصل في عرض المواد على الموقع، وهي ليست ملزمة بأي حال من الأحوال بعرضها كلها أو جزء منها على الموقع .</p>

            <p>4- حقوق الطبع والنشر والملكية الفكرية</p>
            <p>  لا يجوز تفسير أي نص من النصوص الواردة في هذه الوثيقة -بأي حال من الأحوال- على أنه منح للحق أو الترخيص لأي علامة تجارية أو امتياز "للموقع" أو الشركات التابعة أو أي طرف ثالث. </p>
            <p>ن جميع حقوق الطبع والنشر والعلامات التجارية وحقوق الملكية الفكرية الأخرى في هذا الموقع (بما فيها التصميم) وجميع المواد والمحتويات المزودة كجزء من الموقع، ستبقى دائما ملكا "للموقع" </p>
            <p>أومن يمنحها التراخيص</p>
            <p>ولدى ولوجك إلى هذا الموقع، فإنك توافق على القيام بذلك لأغراضك الشخصية فقط وليس للاستخدام التجاري. </p>
            <p>يجدر بك عدم الموافقة على تخويل أو مساعدة أي طرف ثالث بنسخ وتحميل وإرسال أو تخزين (في أي موقع آخر) أو توزيع أو بث أو نشر أو استغلال أي مادة أو محتوى بشكل تجاري أو تعديلها، دون إذن </p>
            <p>كتابي مسبق من قبل مسؤول مخول في "الموقع". </p>

            <p>5- مواقع وروابط الطرف الثالث </p>
            <p>"الموقع"  غير مسؤول عن وجود اي محتوى او مواد منشورة  في مواقع الطرف الثالث أو مواد تلج إليها من خلال هذا الموقع. فإذا قررت زيارة أي موقع عبر رابطه، فإنك تتحمل مخاطر ومسؤولية اتخاذ   . </p>
            <p>جميع الإجراءات الحمائية ضد الفيروسات الآفات المدمرة الأخرى. </p>
            <p>"الموقع" لا يعتمد ولا يتحمل مسؤولية أي محتوى أو إعلان أو منتجات أو خدمات أو معلومات متوفرة من قبل مواقع طرف ثالث أو مواد (تتضمن دفع ثمن وتوصيل مثل تلك المنتجات أو الخدمات). </p>
            <p>و"الموقع" غير مسؤول عن أي أضرار أو خسارة أو إهانة تسبب فيها أي محتوى أو لها صلة بأي منتجات وخدمات ومعلومات متاحة على مثل تلك المواقع. وأي شروط وأحكام وتعهدات تتعلق بمثل هذه </p>
            <p>لتعاملات تكون فقط بينك وبين مزود هذه الخدمات. </p>

            <p> 6-المشتركين الموثقين الذين لديهم صلاحيات ادخال بيانات والذين يقومون باضافة بيانات الى الموقع عبر تبويبات لاضافة بيانات متنوعة يتم مطالبتهم باثبات هويتهم عن طريق تحميل وثائق تعريفية لهم للتأكد انه ليس حساب زائف وبناء على ذالك يتحمل المشترك كامل المسؤلية القانونية والانسانية والدينية لأي معلومة يقوم بادخالها عبر هذه التبويبات ولا مسؤلية تترتب على الموقع او إدارة الموقع</p>

           <p>7-كما يتم مطالبة المشرفين في الموقع باثبات هويتهم الحقيقية بالوثائق للتأكد من شخصيتهم وعلى ذالك يترتب عليهم كامل المسؤلية القانونية والانسانية والدينية بخصوص كل البيانات التي </p>
           <p>يقومون باضافتها على الموقع او التي يقومون بالموافقة عليها المضافة من قبل المشتركين ولا يترتب اي مسؤلية على الموقع او ادارة الموقع</p>
           <p>8- تحديد المسؤولية</p>
           <p>بموجب هذا فإنت توافق على أنك وحدك كزائر او مشترك أو مشرف تتحمل مسؤولية استخدامك لهذا الموقع. فبالحد الأقصى الذي يسمح به القانون، فإن "الموقع" وموظفيه والقائمين عليها ووكلائه لا </p>
           <p>يتحملون مسؤولية الضمانات المرتبطة بهذا الموقع، سواء كان ذلك صراحة أو بشكل ضمني. </p>
           <p>و"الموقع" لا يضمن بأن أي محتوى يرسل عبر هذا الموقع مطابق للمعايير الاجتماعية والثقافية والأخلاقية المقبولة. كما أن "الموقع" لا يتعهد بأن المواد المعروضة "مشاركات المستخدم" لا تنتهك </p>
           <p>حقوق الطرف الثالث. </p>
           <p>فرغم أن "الموقع" لا يراقب مواد "مشاركات المستخدم" فإنك تتحمل المسؤولية الكاملة للحصول على حقوق الطرف الثالث وجميع الموافقات الرسمية اللازمة لإرسال أي مادة، وبموجب ذلك تتحمل </p>
           <p>مسؤولية جميع الأضرار والتكاليف التي قد تترتب على استخدامك لهذا الموقع. </p>
           <p>إن المعلومات والمواد الأخرى المتاحة على هذا الموقع قد تحتوي على معلومات غير دقيقة وأخطاء طباعية، و"الموقع" لا يتعهد بدقة أو اكتمال أو مدى مصداقية أي بيان أومعلومة منشورة أو موزعة </p>
           <p>من خلال هذا الموقع (منها على سبيل المثال للحصر، المعلومات المتوفرة عبر استخدام أي من البرمجيات). </p>
           <p>وتقر بأنك ستتحمل وحدك مخاطر أي اعتماد على مثل ذلك البيان أو المعلومات. و"للموقع" الحق الحصري في عمليات تصحيح الأخطاء وحذفها في أي جزء من أجزاء الموقع، وإجراء تغيير على الموقع </p>
           <p>والمواد والمنتجات والبرامج والخدمات والأسعار المنشورة على الموقع في أي وقت دون إشعار مسبق.  </p>
           <p>"الموقع" و/أو الأطراف الثالثة لن تكون مسؤولية بأي حال من الأحوال عن أي ضرر قد يشمل -على سبيل المثال لا الحصر- الأضرار  المترتبة أو غير المباشرة، أو أي أضرار تتضمن -على سبيل المثال لا الحصر- </p>
           <p>الأخطاء وعمليات الحذف، والأضرار المترتبة أو غير المباشرة، أو أي أضرار قد تنشأ عن الاستخدام أو عدم القدرة على الاستخدام أو الاستفادة من البيانات سواء كان ذلك بسبب العقد أو الإهمال أو أي سبب </p>
           <p>خر يتعلق باستخدام الموقع. </p>
           <p>"الموقع" لا يضمن استمرارية الوظائف المتوفرة في هذا الموقع، أو خلوها من الأخطاء أو تصحيح الأخطاء أو خلو هذا الموقع أو الخادم الذي يجعله متاحا، من الفيروسات أو آفات البرامج.</p>
           <p>و"الموقع" لا تمثل مدى الفعالية الكاملة والدقة والمصداقية الخاصة بأي مادة منشورة. وربما يقوم "الموقع" بحذف وتغيير وتعليق أي جانب من جوانب الموقع بما في ذلك إتاحة أي خاصية للموقع في </p>
           <p>أي وقت دون إشعار.</p>
          </div>

    </>
  )
}
